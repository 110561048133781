body {
	touch-action: pan-x pan-y;
}

.banner {
	width: 100%;
}

.banner img {
	object-fit: cover;
	width: 100%;
}

.main-wrapper {
	width: 100%;
	justify-content: center;
	align-items: center;
	padding: 1rem;
	margin: 0;
}

.main-content {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.search {
	width: 100%;
	margin-bottom: 1rem;
	display: flex;
	align-items: center;
	gap: 0.75rem;
}

.search .custom-select {
	margin-left: auto;
}

.search-wrapper {
	width: 100%;
	max-width: 17.5rem;
	grid-column-gap: 0.375rem;
	border: 1px solid var(--theme--3);
	background-color: var(--theme--1);
	border-radius: 0.5rem;
	align-items: center;
	padding: 0.625rem 1rem 0.625rem 1rem;
	display: flex;
}

.search-wrapper:hover {
	border-color: var(--theme--4);
}

.field-search {
	width: 100%;
	height: 1.375rem;
	color: var(--tertiary--1);
	background-color: transparent;
	border: 1px solid transparent;
	margin-bottom: 0;
	padding: 0 1rem 0 0;
	font-size: 0.875rem;
	line-height: 1.375rem;
	border-style: none;
}

.filters {
	gap: 0.5rem;
	flex-wrap: wrap;
	align-content: flex-start;
	justify-content: space-between;
	align-items: center;
	display: flex;
	margin-bottom: 1.2rem;
}

.filter-dropdown,
.custom-select {
	display: inline-block;
	position: relative;
}

.filter-dropdown {
	flex-grow: 1;
}

.dropdown-toggle,
.custom-select-dropdown-toggle {
	gap: 0.25rem;
	flex-direction: row;

	justify-content: center;
	align-items: center;
	text-decoration: none;
	display: flex;
	z-index: 1;
	height: 1.7rem;
	border: 1px solid var(--theme--3);
	color: var(--tertiary--1);
	background-color: transparent;
	padding: 0 0.25rem;
	font-size: 0.5rem;
	line-height: 0.875rem;
	text-align: center;
	text-transform: uppercase;
	border-radius: 0.3rem;
	margin-left: 0;
	font-weight: 700;
	transition: all 0.14s ease-in-out;
	cursor: pointer;
}

.custom-select-dropdown-toggle {
	width: 2.25rem;
}

.custom-select-dropdown-toggle {
	background-color: var(--primary--3);
}

.dropdown-toggle:active, .dropdown-toggle.opened {
	border-style: solid;
	border-color: var(--primary--3);
	background-color: var(--primary--3);
}

.dropdown-toggle:active .filter-count, .dropdown-toggle.opened .filter-count {
	background-color: #17161c;
	color: #ffcc29;
}

.dropdown-toggle img {
	width: 0.5rem;
	height: 0.5rem;
}

.filters button.dropdown-toggle {
	width: 2.25rem;
}

.clear-search {
	cursor: pointer;
	fill: #17161c !important;
	filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(223deg) brightness(97%) contrast(107%);
	width: 16px;
	height: 16px;
}

.filter-count {
	width: 1rem;
	height: 1rem;
	background-color: var(--primary--3);
	border-radius: 0.188rem;
	justify-content: center;
	align-items: center;
	font-size: 0.625rem;
	font-weight: 500;
	display: flex;
}

.icon-check {
	justify-content: center;
	align-items: center;
	width: 16px;
	height: 16px;
}

.filter-items-list,
.custom-select-list {
	z-index: 2;
	max-height: 33.625rem;
	overflow-y: scroll;
	background-color: #fff;
	border-radius: 0.5rem;
	padding-top: 0.375rem;
	padding-bottom: 0.375rem;
	box-shadow: 0 1px 12px rgba(0, 0, 0, 0.09);
	position: absolute;
	min-width: 100%;
}

.filter-items-list {
	top: 2.75rem;
	width: 16.5rem;
}

.custom-select-list {
	width: 1rem;
}

.filter-checkbox {
	grid-column-gap: 0.75rem;
	align-items: center;
	margin-bottom: 0;
	padding: 0.75rem 1rem;
	display: flex;
	position: relative;
	font-weight: 500;
	font-size: 1rem;
}

.custom-checkbox {
	width: 1.5rem;
	height: 1.5rem;
	border-color: var(--theme--3);
	background-color: var(--theme--1);
	border-radius: 0.375rem;
	margin-top: 0;
	border: 1px solid #ccc;
}

.custom-hide-checkbox {
	opacity: 0;
	position: absolute;
	z-index: -1;
}

.custom-checkbox-label,
.custom-select-label {
	margin-bottom: 0;
	font-weight: 500;
	line-height: 1.5rem;
	cursor: pointer;
}

.custom-select-label {
	display: flex;
	justify-content: center;
	height: 2rem;
	align-items: center;
	font-size: 1rem;
}

.custom-hide-checkbox:checked ~ .custom-checkbox {
	border-style: none;
	border-color: var(--primary--3);
	background-color: var(--primary--3);
	background-image: url('../check.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 0.563rem 0.5rem;
	border-radius: 0.375rem;
}

.card {
	width: 23%;
	border-radius: 0.5rem;
	background-position: center;
	background-size: cover;
	position: relative;
	cursor: pointer;
	transition: transform 0.25s ease;
}

.card.zoomed,
.card:hover {
	transform: scale(1.07);
}

.card.zoomed {
	border: none;
	animation: zoomAndShake 0.4s forwards;
}

@keyframes zoomAndShake {
	0% {
		transform: scale(1);
	}
	30% {
		transform: scale(1.07);
	}
	70% {
		transform: scale(1);
	}
	100% {
		transform: scale(1.07);
	}
}

.card:before {
	content: ' ';
	width: 100%;
	padding-bottom: 126%;
	display: block;
}

.games .card:nth-child(4n) {
	margin-right: 0;
}

.card-play-btn {
	position: absolute;
	bottom: 0.5rem;
	left: 50%;
	transform: translateX(-50%);
	grid-column-gap: 0.625rem;
	grid-row-gap: 0.625rem;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	display: flex;
	text-transform: uppercase;
	border-radius: 0.5rem;
	margin: 0;
	padding: 1rem 0rem;
	width: calc(100% - 0.25rem);
	font-family:
		IBM Plex Sans,
		sans-serif;
	max-height: 2.125rem;
	font-size: 0.625rem;
	font-weight: 700;
	line-height: 0.875rem;
	background-color: rgba(0, 0, 0, 0.75);
	color: var(--primary--3);
	border-color: var(--primary--3);
	cursor: pointer;
}

.card-play-btn img {
	width: 1.2rem;
	height: 1.2rem;
}

.games {
	padding: 0.8rem 0;
}

.games-wrapper {
	gap: 1rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.navigation {
	display: flex;
	align-items: center;
	justify-content: end;
}

.more-btn {
	text-align: center;
	text-transform: uppercase;
	border-radius: 0.5rem;
	margin-left: 0;
	height: 2rem;
	width: 100%;
	font-family:
		IBM Plex Sans,
		sans-serif;
	font-size: 0.6rem;
	font-weight: 700;
	line-height: 0.875rem;
	text-decoration: none;
	transition: all 0.14s ease-in-out;
	background: var(--primary--3);
	color: var(--tertiary--1);
	white-space: nowrap;
	border-style: none;
	margin-right: 0;
	cursor: pointer;
}

.no-result {
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.no-result img {
	width: 5.75rem;
	height: 5.75rem;
	margin: 1.25rem;
}

.no-result h2 {
	font-family:
		IBM Plex Sans,
		sans-serif;
	font-size: 1.25rem;
	font-weight: 700;
	line-height: 1.875rem;
	margin-bottom: 0;
}

.no-result p {
	color: var(--theme--5);
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.375rem;
	margin-top: 0;
}

.popup {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.92);
	z-index: 10;
}

.popup-content-classic {
	max-height: 88vw;
}

.popup-content {
	width: 100%;
	height: 100%;
}

.popup-content iframe {
	border: none;
	position: relative;
	z-index: 0;
}

.popup-close {
	position: absolute;
	background-color: var(--primary--3);
	color: var(--tertiary--1);
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	bottom: min(16.6vh, calc(100vw / 1080 * 290));
	left: -2.6vmax;
	height: 3vmax;
	font-size: 1.4vmax;
	padding: 1.5vmax 1.5vmax 1.5vmax 3.5vmax;
	border-radius: 2.3vmax;
	z-index: 21;
}

.popup-close img {
	width: 16px;
	height: 16px;
}

.search-icon {
	width: 16px;
	height: 16px;
}

.sticky-btn {
	padding: 0.75rem 1.125rem;
	font-size: 1rem;
	background-color: #ffcc29;
	color: var(--tertiary--1);
	border-radius: 0.75rem;
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 18px;
	border: none;
	text-transform: uppercase;
	font-weight: 700;
	cursor: pointer;
}

@media screen and (max-width: 3900px) {
	body {
		font-size: 21px;
	}
}

@media screen and (max-width: 2100px) {
	body {
		font-size: 1rem;
	}
}

@media screen and (max-width: 1399px) {
	.main-wrapper {
		padding: 1rem;
	}
}

@media screen and (max-width: 991px) {
	.main-wrapper {
		padding: 1rem;
	}
}

@media screen and (max-width: 591px) {
	.card {
		margin-right: 0;
		width: 46%;
	}

	.main-wrapper {
		padding: 1.5rem;
	}
}

@media screen and (min-width: 1080px) {
	.icon-check,
	.search-icon {
		width: 1.125rem;
		height: 1.125rem;
	}

	.sticky-btn-img {
		width: 1rem;
		height: 1rem;
	}
}
