.wrapper {
	width: 100%;
	margin-top: 0.5rem;
}

.container {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	width: 100%;
	height: 100%;
}

.header {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.title {
	font-size: 1rem;
	font-family: 'Cairo';
	text-transform: uppercase;
	color: #17161C;
	font-weight: 900;
	letter-spacing: 1px;
}

.button {
	padding: 10px 20px;
	border-radius: 5px;
	background-color: #ffcc29;
	text-transform: uppercase;
	font-size: 0.5rem;
	font-weight: bold;
	display: inline-flex;
	align-items: center;
	border: none;
	cursor: pointer;

	transition: box-shadow 0.2s ease;
}
.button:active {
	box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}

.icon {
	width: 0.5rem;
	height: 0.5rem;
	background-image: url('../../assets/random-icon.png');
	background-size: contain;
	background-repeat: no-repeat;
	margin-left: 8px;
}

.list {
	display: flex;
	gap: 0.5rem;
}

.scrollable {
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: 4.8rem; /* Ширина одной карточки */
	overflow-x: scroll;
	width: calc(6 * 6.5rem);
}
.card {
	padding-bottom: 0.3rem;
}

.hidden {
	visibility: hidden;
}
