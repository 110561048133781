.modal {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	bottom: 0.5rem;
	left: 50%;
	transform: translateX(-50%);
	border-radius: 50px;
	background-color: white;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	padding: 0.25rem 0.5rem;
	z-index: 2;
}

.content_wrapper {
	display: flex;
	justify-content: space-between;
	gap: 0.5rem;
}

.content {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	width: 1.5rem;
	height: 1.5rem;
	border: none;
	cursor: pointer;
	transition: box-shadow 0.2s ease;
	outline: none;
}
.content_active {
	background-color: #ffcc29;
}

.content:active {
	box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}

.icon_wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
}

.icon_text {
	font-size: 0.6rem;
	font-weight: bold;
	text-transform: uppercase;
}

/* кнопки переключения языка */
.vertical_wrapper {
	display: flex;
	flex-direction: column;
	background-color: white;
	border-radius: 1.25rem;
	background-color: white;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	position: absolute;
	bottom: 0.25rem;
}

.content_switchable {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background: none;
	border: none;
	width: 1.5rem;
	height: 1.5rem;
	border: none;
	border-radius: 50%;
	cursor: pointer;
}

.content_empty {
	width: 1.5rem;
	height: 1.5rem;
}
