.game_info_container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	border-radius: 10px;
	gap: 0.5rem;
}

.button_view_back {
	width: 3rem;
	height: 6.5rem;
	background: #FFCC29;
	border-radius: 0.4rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.button_view_back img {
	width: 0.7rem;
}

.game_image {
	width: 7.5rem;
	height: 6.5rem;
	background-position: center;
	background-size: cover;
	border-radius: 0.4rem;
}

.details_wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 6.5rem;
	background-color: #f5f5f5;
	padding: 1rem 1rem;
	max-height: 8rem;
	border-radius: 0.4rem;
}

.details_container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	margin: 0 0.5rem;
	gap: 0.5rem;
	padding: 0 0.4rem;
}

.info_container {
	height: 100%;
	width: 35%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.game_title {
	display: flex;
	font-family: 'Cairo';
	font-size: 0.95rem;
	font-weight: 900;
	text-transform: uppercase;
	margin-top: -0.2rem;
	color: #17161C;
}

.details_coll {
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: space-between;
	height: 100%;
}

.detail_item {
	display: flex;
	flex-direction: column;
	position: relative;
}

.detail_item_left_border {
	display: flex;
	flex-direction: column;
	position: relative;
}

.detail_item_left_border::before {
	border-left: 0.01rem solid #e4e1ed;
	position: absolute;
	content: '';
	left: -0.8rem;
	height: 70%;
	transform: translateY(15%);
}

.detail_label {
	font-size: 0.5rem;
	color: #888596;
	margin-bottom: 0.1rem;
	text-transform: uppercase;
}

.detail_value {
	font-size: 0.6rem;
	font-weight: 700;
	color: #000;
	margin-bottom: 0.5rem;
}
