.games-category-title {
	text-transform: uppercase;
	color: var(--tertiary--1);
	font-size: 1rem;
	font-weight: 900;
	font-family: 'Cairo';
	margin-bottom: 0.4rem;
}

.games-category-list {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 0.5rem;
}

.games-category-list .game-card {
	height: 9rem;
	width: auto;
}
