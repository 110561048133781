.info_container {
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	width: 100%;
	gap: 0.5rem;
	flex-basis: fit-content;
}

.button_view_back {
	min-width: 1.7rem;
	flex-basis: fit-content;
	background: #FFCC29;
	border-radius: 0.4rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.button_view_back img {
	width: 0.7rem;
}

.wrapper {
	background-color: #f3f2f7;
	border-radius: 0.5rem;
	padding: 0.8rem;
}

.container {
	display: grid;
	grid-template-columns: 1fr;
	gap: 0.5rem;
}

.background_image {
	width: 12rem;
	height: 6rem;
	background-position: center;
	background-size: cover;
	border-radius: 0.2rem;
}
.wrapper_text {
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: start;
	gap: 0.7rem;
}

.main_text {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.text_header {
	font-size: 1rem;
	text-transform: uppercase;
	color: #17161C;
	font-family: 'Cairo';
	font-weight: 900;
	letter-spacing: 1px;
}

.title {
	font-size: 0.45rem;
	font-weight: 700;
}

.subtitle {
	font-size: 0.45rem;
	color: #17161c;
}

.row {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.text_wrapper {
	display: flex;
	flex-direction: column;
	gap: 0.2rem;
}

.columns {
	display: flex;
	flex-direction: column;
	justify-content: left;
	align-items: start;
	gap: 1rem;
}
