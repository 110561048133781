.free-spins-modal {
	padding: 2rem;
	box-sizing: border-box;
	background: #fff;
	border-radius: 0.3rem;
	width: 100%;
}

.free-spins-modal-container {
	display: flex;
	justify-content: space-around;
}

.free-spins-content {
	width: 11.5rem;
}

.free-spins-title {
	color: var(--tertiary--1);
	font-size: 1rem;
	font-family: 'Cairo';
	font-weight: 900;
	text-transform: uppercase;
	margin-bottom: 0.2rem;
}

.free-spins-description {
	margin-top: 0.5rem;
	color: var(--tertiary--1);
	font-size: 0.5rem;
	font-weight: 400;
	line-height: 0.7rem;
	word-wrap: break-word;
}

.free-spins-description-margin {
	padding-bottom: 0.6rem;
}

.free-spins-img {
	width: 12rem;
}

.free-spins-img img {
	width: 100%;
}

.free-spins-hint-title {
	display: flex;
	margin-top: 0.4rem;
	align-items: center;
	color: var(--tertiary--1);
	font-size: 0.5rem;
	font-family: IBM Plex Sans;
	font-weight: 700;
}

.free-spins-hint-title img {
	width: 0.6rem;
	margin-right: 0.3rem;
}

.free-spins-hint-description {
	margin-top: 0.1rem;
	margin-left: 0.9rem;
	color: var(--tertiary--1);
	font-size: 0.5rem;
	font-family: IBM Plex Sans;
	font-weight: 400;
	line-height: 24px;
}

.free-spins-btn-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 1.3rem;
}

.free-spins-btn {
	padding: 0.5rem 4rem;
	background: var(--primary--3);
	color: var(--tertiary--1);
	box-sizing: border-box;
	border: none;
	font-size: 0.5rem;
	font-weight: 700;
	text-transform: uppercase;
	border-radius: 0.3rem;
	cursor: pointer;
}
