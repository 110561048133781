.modal {
	top: 0;
	left: 0;
	position: fixed;
	background-color: #fff;
	width: 100%;
	height: 100%;
	border-radius: 0;
	padding: 0;
	margin: 0;
	overflow-y: auto;
	z-index: 1000;
	box-sizing: border-box;
}

.template {
	width: 100%;
	overflow: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.5rem;
	box-sizing: border-box;
}

.modal_banner {
	padding: 1rem 1rem 0 1rem;
	box-sizing: border-box;
}

.iframe_wrapper {
	width: 100%;
	height: 28rem;
	background-color: #000000;
}

.iframe {
	width: 100%;
	height: 100%;
	border: none;
}
.content_template {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	gap: 0.5rem;
	padding: 0 1rem;
	box-sizing: border-box;
	overflow: hidden;
	margin-top: 0.8rem;
}
.button_container {
	display: flex;
	gap: 10px;
	width: 100%;
}

.button {
	padding: 10px 20px;
	border-radius: 5px;
	font-size: 0.5rem;
	border: none;
	font-weight: bold;
	width: 100%;
	cursor: pointer;
}
.button:active {
	box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}

.button_back {
	background-color: #ffffff;
	color: #000000;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.button_similar {
	background-color: #ffcc29;
	color: #000000;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.empty_block {
	width: 100%;
	height: 2.5rem;
}
