.gamification {
	margin-top: 1rem;
}

.gamification-title {
	font-size: 1rem;
	line-height: 1rem;
	text-transform: uppercase;
	color: var(--tertiary--1);
	font-family: 'Cairo';
	font-weight: 900;
	margin-bottom: 0.6rem;
}

.gamification-container {
	display: flex;
	padding-bottom: 0.6rem;
	margin-bottom: 1rem;
	flex-wrap: nowrap;
	flex-direction: row;
	justify-content: space-between;
}

.gamification-card {
	padding: 0.8rem;
	width: 12.2rem;
	background: var(--theme--2);
	box-sizing: border-box;
	border-radius: 0.3rem;
	cursor: pointer;
}

.gamification-card-img img {
	width: 10rem;
	height: 12.6rem;
}

.gamification-card-title {
	margin-top: 0.5rem;
	font-size: 0.6rem;
	text-transform: uppercase;
	font-family: 'Cairo';
	font-weight: 800;
}

.conversation {
	display: flex;
	background: var(--primary--3);
	color: var(--tertiary--1);
	padding-top: 1.7rem;
	border-radius: 0.3rem;
	box-sizing: border-box;
	justify-content: center;
	margin-bottom: 0.6rem;
}

.conversation-content {
	margin-top: 1rem;
	width: 9.3rem;
}

.conversation-title {
	font-size: 1rem;
	line-height: 1.3rem;
	font-family: 'Cairo';
	font-weight: 900;
	text-transform: uppercase;
}

.conversation-text {
	margin-top: 0.5rem;
	font-size: 0.5rem;
	line-height: 0.6rem;
	font-weight: 400;
}

.conversation-img {
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	width: 14rem;
	margin-left: -1rem;
}

.conversation-img img {
	width: 100%;
}
