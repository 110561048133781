::-webkit-scrollbar {
	width: 0.2rem;
	height: 0.2rem;
}

::-webkit-scrollbar-thumb {
	background-color: var(--primary--3);
	border-radius: 0.1rem;
}

::-webkit-scrollbar-thumb:hover {
	background-color: var(--primary--2);
}

::-webkit-scrollbar-track {
	background-color: var(--theme--2);
}

::-webkit-scrollbar-corner {
	background-color: transparent;
}
