.games-filters {
	width: 100%;
	margin: 0;
	padding-top: 14rem;
}

.games-filters-dropdown {
	position: fixed;
	padding: 1rem;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	box-sizing: border-box;
	z-index: 1001;
}

.games-filters-container {
	padding: 1rem;
	background: #fff;
	box-sizing: border-box;
	border-radius: 0.3rem;
}

.games-filters-close-container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 0.3rem;
}

.games-filters-close {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	width: 2.2rem;
	height: 2.2rem;
	background: var(--primary--3);
	cursor: pointer;
}

.games-filters-close img {
	width: 0.7rem;
}

.games-search {
	display: flex;
	align-items: center;
	background: #fff;
	gap: 0.2rem;
	border-radius: 0.3rem;
	box-sizing: border-box;
	padding: 0.4rem 0.5rem;
	margin-bottom: 0.5rem;
}

.games-search-loupe {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 1rem;
}

.games-search-close {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 0.5rem;
	min-width: 0.5rem;
	cursor: pointer;
}

.games-search img {
	width: 100%;
}

.games-search input {
	width: 100%;
	color: var(--tertiary--1);
	font-size: 0.8rem;
	font-family:
		IBM Plex Sans,
		sans-serif;
	font-weight: 400;
	line-height: 0.8rem;
	padding: 0.2rem;
	box-sizing: border-box;
	background: #fff;
	border: none;
	border-radius: 0.3rem;
}

.games-search input::placeholder,
.games-search input::-ms-input-placeholder,
.games-search input::-webkit-input-placeholder {
	color: var(--theme--4);
	line-height: 0.8rem;
	font-weight: 300;
}

.filter-title img {
	height: 0.8rem;
	margin-left: 0.2rem;
}

.games-filters-tabs {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 0.1rem;
	padding: 0.2rem;
	box-sizing: border-box;
	margin-bottom: 0.5rem;
	border: 1px solid var(--theme--3);
	border-radius: 0.3rem;
}

.game-rows {
	min-height: 20rem;
	height: calc(100vh - 34rem);
	overflow: auto;
	margin-bottom: 1rem;
}

.game-row {
	display: flex;
	gap: 1rem;
	margin-bottom: 0.8rem;
}

.game-row-content {
	display: block;
}

.game-row-title {
	margin-top: 0.2rem;
	color: var(--tertiary--1);
	font-size: 1.2rem;
	font-weight: 900;
	font-family: 'Cairo';
	text-transform: uppercase;
	letter-spacing: 1px;
}

.game-row-type {
	color: var(--tertiary--1);
	font-size: 0.7rem;
	font-weight: 400;
	text-transform: capitalize;
}

.game-row-img {
	width: 11rem;
}

.game-row-img img {
	width: 100%;
	border-radius: 0.3rem;
	box-sizing: border-box;
}

.game-row-btn {
	margin-top: 0.8rem;
	font-size: 0.7rem;
	text-transform: uppercase;
	font-weight: 700;
	padding: 0.5rem 1.6rem;
	color: var(--tertiary--1);
	background: var(--primary--3);
	border-radius: 0.3rem;
	box-sizing: border-box;
	border: none;
	outline: none;
	cursor: pointer;
}
